import { BehaviorSubject, Observable, retry } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvironmentService } from "../../../environment.service";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class CaptainTalkBaseService {
  onSetSystems = new BehaviorSubject<{ key: number; value: string }[]>(null);
  isBoatSpecificsComplete = new BehaviorSubject(null);

  constructor(
    private httpClient: HttpClient,
    private env: EnvironmentService
  ) {}

  getAllParts(): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetSupportedTypes`
    );
  }

  // Troubleshooting starts

  getSymptomsByPartType(partType: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetSymptoms?partType=${partType}`
    );
  }

  getSymptomResults(partType: number, symptomId: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetSymptomResult?partType=${partType}&symptomId=${symptomId}`
    );
  }

  // Troubleshooting ends

  // Maintenance starts

  getMaintenanceSchedules(partType: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetMaintenanceList?partType=${partType}`
    );
  }

  getMaintenanceDetails(
    partType: number,
    maintenanceId: number
  ): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetMaintenanceResult?partType=${partType}&maintenanceId=${maintenanceId}`
    );
  }

  // Maintenance ends

  // Repair starts

  getRepairProcesses(partType: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetRepairList?partType=${partType}`
    );
  }

  getRepairDetails(partType: number, maintenanceId: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/PartKnowledgeBase/GetRepairResult?partType=${partType}&maintenanceId=${maintenanceId}`
    );
  }

  // Repair ends

  // youtube starts

  getVideoDetails(videos: string[]): Observable<any> {
    return this.httpClient
      .post(
        `${this.env.apiUrl}services/app/Utility/GetYoutubeVideoInfo`,
        videos,
        { headers: { "content-type": "application/json" } }
      )
      .pipe(retry(3));
  }

  // youtube ends

  getServicesForUser(): Observable<any> {
    return this.httpClient.get(
      this.env.apiUrl + "services/app/ServiceOrders/GetServicesForUser"
    );
  }

  getBoatDetails(boatInfo): Observable<any> {
    return this.httpClient.get(
      `${this.env.apiUrl}services/app/CaptainAI/GetBoatDetails?Manufacturer=${boatInfo.Manufacturer}&MakeModel=${boatInfo.MakeModel}&YearBuilt=${boatInfo.YearBuilt}`,
      { headers: { "content-type": "application/json" } }
    );
  }

  // Data for the chatbot
  setSystems(systems: { key: number; value: string }[]) {
    this.onSetSystems.next(systems);
  }

  // AI Chatbot starts

  getAiResponse(request: {
    prompt: string;
    context: string;
    session_id?: string;
    image?: File;
    language:string
  }): Observable<any> {
    let prompt = "";
    let context = "";

    if (request?.prompt?.length === 0 || !request?.prompt) {
      prompt = "";
    } else {
      prompt = request?.prompt;
    }

    if (request?.context?.length === 0 || !request?.context) {
      context = "";
    } else {
      context = request?.context;
    }

    const formData = new FormData();
    formData.append("prompt", prompt);
    formData.append("context", context);
    formData.append("language", request.language)
    if (request.image) {
      formData.append("image", request.image);
    }

    let headers = new HttpHeaders();
    if (request.session_id) {
      headers = headers.set("session_id", request.session_id);
    }

    return this.httpClient.post(
      `https://captainai.boatbot.ai/dynamic_query`,
      formData,
      { headers }
    );
  }
}
