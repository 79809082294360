import { Injectable } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { YachtMessagingComponent } from "../yacht-owner/messaging/yacht-messaging/yacht-messaging.component";
import { SignalrService } from "../yacht-owner/signalr.service";
import { ToastService } from "../shared/message/toast.service-impl";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class MessagingService {
  activeChatModal: HTMLIonModalElement;
  isChatOpen = false;
  chatLogs: any;
  constructor(
    private modalCtrl: ModalController,
    private signalrService: SignalrService,
    private toastService: ToastService,
    private router: Router
  ) {
    this.signalrService.friendListChange.subscribe((data) => {
      if (data) {
        this.chatLogs = data;
      }
    });
  }

  async openChat(vendorId: number) {
    this.isChatOpen = true;
    this.closeActiveChat();

    if (!vendorId) {
      console.error("Vendor not available.");
      return;
    }
    // this.signalrService.chat?.Users.length > 0 ? this.signalrService.chat?.Users : await this.signalrService.getFriendsChatsAndSettings();

    console.log("chatLogs", this.chatLogs);
    this.isChatOpen = false;

    if (!this.chatLogs) {
      console.error("Chat logs not available.");
      return;
    }

    const vendorChat = this.chatLogs.find((item) => item.Id === vendorId);

    if (!vendorChat) {
      // console.error("Vendor chat not available.");
      this.toastService.showToast("Vendor chat not available.");
      return;
    }


    this.router.navigate([`/yacht-owner/yacht-messages/${vendorChat.Id}/chat/`]);

    // this.activeChatModal = await this.modalCtrl.create({
    //   component: YachtMessagingComponent,
    //   componentProps: {
    //     data: vendorChat,
    //   },
    // });

    // this.activeChatModal.present();
  }

  async closeActiveChat() {
    if (this.activeChatModal) await this.activeChatModal.dismiss();
  }
}
