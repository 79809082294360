import { Component, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.css'],
})
export class ContextMenuComponent {
  showMenu = false;
  menuStyle = {};
  @Output() contextAction = new EventEmitter<string>();
  actions = [
    { name: 'Copy Text', icon: 'content-copy', action: 'copy' },
    { name: 'Good Response', icon: 'thumb-up', action: 'good_response' },
    { name: 'Bad Response', icon: 'thumb-down', action: 'bad_response' },
  ]

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  showContextMenu(event: any) {
    console.log("Event===>",event);
    this.menuStyle = {
      top: `${event.clientY}px`,
      left: `${event.clientX}px`,
    };
    this.showMenu = true;
  }

  hideContextMenu() {
    console.log("Hide Menu");
    this.showMenu = false;
  }

  onAction(action: string) {
    console.log(action);
    this.contextAction.emit(action);
    this.hideContextMenu();
  }
}