<app-context-menu
  (contextAction)="contextActionEvent($event)"
></app-context-menu>
<app-base-two-layout
  (goBack)="goBack()"
  class="h-full"
  (textAreaValue)="sendQuestion($event)"
  (click)="dismissMenu()"
  [headerText]="'CaptainAI'"
  [hasGreeting]="false"
  [hasHeader]="true"
  [showAiLogo]="false"
  [hasTextArea]="false"
  [placeholder]="
    LANGUAGE_DATA?.CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.TYPE_OR_TALK
      | translate
  "
>
  <div header>
    <div
      class="p-2 bg-white border border-b border-b-[#8BCCE1] w-full flex flex-col items-end gap-2"
    >
      <div class="flex items-center gap-2 w-full">
        <img
          src="assets/captain-talk/captain-talk.svg"
          alt="captain talk"
          srcset=""
          class="w-16 flex-none"
        />
        <form
          class="border border-[#8BCCE1] px-6 py-1 rounded-full flex items-center justify-start grow min-h-[56px] gap-2"
          (submit)="submitPrompt()"
          [formGroup]="conversationForm"
        >
          <input
            class="border-none h-[48px] grow outline-none ring-0"
            [placeholder]="
              LANGUAGE_DATA?.CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS
                .TYPE_OR_TALK | translate
            "
            [formControlName]="'prompt'"
            #prompt
          />
          <button
            type="submit"
            class="button-pressed-plain flex-none p-2 flex items-center justify-center relative"
            [disabled]="!conversationForm?.get('prompt').value"
            *ngIf="!isListening"
          >
            <span
              class="bbicon-send-message text-3xl text-brandSunsetOrange"
            ></span>
          </button>
          <button
            class="flex-none p-1 flex items-center justify-center rounded-full border-dsError border-2 border-solid relative"
            (click)="stopListening()"
            *ngIf="isListening"
          >
            <span class="w-3 h-3 block rounded bg-dsError"></span>
            <div class="mic-pulser w-11 h-11"></div>
          </button>
        </form>
      </div>
      <span class="text-xs w-[76%]">
        {{ LANGUAGE_DATA?.CAPTAIN_TALK.DISCLAIMER | translate }}
      </span>
      <div class="relative" *ngIf="this.image?.image">
        <img [src]="this.image?.image" class="w-16 h-16 rounded-lg mr-2" />
        <button
          class="rounded-full bg-dsGray60 absolute -top-1 -left-1 w-6 h-6 flex items-center justify-center"
          (click)="removeImage()"
        >
          <span class="bbicon-close text-xl text-white"></span>
        </button>
      </div>
    </div>
  </div>
  <div
    class="h-full overflow-y-auto p-2"
    #conversationContainer
    *ngIf="conversations"
  >
    <div
      *ngFor="let conversation of conversations"
      class="flex w-full {{
        conversation.type === 'bot' ? 'justify-start' : 'justify-end'
      }}"
    >
      <div
        (click)="handleClick($event); $event.stopPropagation()"
        *ngIf="conversation.type === 'bot' && conversation.text.length"
        class="px-3 py-4 w-full bg-[#F8F8F8] rounded-md text-[#1F1F1F] text-base leading-6 mb-3"
        [innerHTML]="conversation.text"
        (touchstart)="onPressStart($event, conversation)"
        (touchend)="onPressEnd($event)"
        (mousedown)="onPressStart($event, conversation)"
        (mouseup)="onPressEnd($event)"
        (mouseleave)="onPressEnd($event)"
      ></div>

      <div *ngIf="conversation.type === 'user'" class="flex justify-end">
        <div
          class="px-3 py-4 max-w-[90%] w-fit bg-brandDeepSeaBlue rounded-tl-xl rounded-b-xl text-white text-base leading-6 mb-3"
        >
          <img
            *ngIf="conversation.image"
            [src]="conversation.image.image"
            alt=""
            srcset=""
            class="w-full h-auto rounded-lg mb-2"
          />
          {{ conversation.text }}
        </div>
        <span
          class="h-6 w-6 rounded-full bg-brandDeepSeaBlue text-white flex items-center justify-center ml-1"
          >{{ user }}</span
        >
      </div>
    </div>
    <ion-spinner
      name="dots"
      *ngIf="isGettingAiResponse && !isTyping"
    ></ion-spinner>
    <ng-container *ngIf="!isFirstPrompt">
      <app-feedback-box
        *ngIf="!isGettingAiResponse && conversations && !isError"
        [isConversational]="false"
        [source]="source"
      ></app-feedback-box>
    </ng-container>

    <app-suggested-question
      (scrollEvent)="scrollToBottom()"
      *ngIf="!isGettingAiResponse && conversations && !isError"
      (getSelectedQuestion)="sendQuestion($event)"
      [suggestedQuestions]="suggestedQuestions"
    ></app-suggested-question>
    <div *ngIf="isError">
      <div class="flex justify-center items-center h-[80%]">
        <div class="flex flex-col items-center">
          <span class="bbicon-error text-5xl text-brandNauticalRed"></span>
          <p class="text-base font-medium text-brandDeepSeaBlue text-center">
            There was an error <br />
            generating a response
          </p>
          <app-button
            (click)="getAiResponse()"
            class="mt-4"
            [variant]="'transparent'"
            [icon]="{ name: 'reload', color: 'black' }"
            [label]="'Retry'"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="grid grid-cols-3 gap-[1px] border-t-[#8BCCE1] border border-t"
    footer
  >
    <button
      class="button-pressed flex-grow flex p-2 py-4 flex-col gap-2 items-center justify-center border-solid border-r border-r-[#8BCCE1] col-span-1"
      (click)="selectCameraOption(true)"
    >
      <img src="assets/captain-talk/ai-lens.svg" alt="" />
      <span class="text-xs font-semibold text-dsGray70">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.CAMERA
          | translate
      }}</span>
    </button>
    <button
      class="button-pressed relative flex-grow flex p-2 py-4 flex-col gap-2 items-center justify-center border-solid border-r border-r-[#8BCCE1] border-l border-l-dsGray20 col-span-1"
      (click)="isListening ? stopListening() : startListening(true)"
    >
      <div class="mic-pulser w-28 h-28" *ngIf="isListening"></div>
      <img
        src="assets/captain-talk/ai-mic.svg"
        alt=""
        [ngClass]="{ 'mic-bouncer': isListening }"
      />
      <span class="text-xs font-semibold text-dsGray70" *ngIf="isListening">
        {{ LANGUAGE_DATA?.CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.STOP
          | translate }}
      </span>
      <span class="text-xs font-semibold text-dsGray70" *ngIf="!isListening">
        {{ LANGUAGE_DATA?.CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.TALK
          | translate }}
      </span>
    </button>
    <button
      class="button-pressed flex-grow flex p-2 py-4 flex-col gap-2 items-center justify-center border-solid border-l border-l-dsGray20 col-span-1"
      (click)="focusPrompt(true)"
    >
      <img src="assets/captain-talk/ai-keyboard.svg" alt="" />
      <span class="text-xs font-semibold text-dsGray70">{{
        LANGUAGE_DATA?.CAPTAIN_TALK.ASK_CAPTAIN_AI.ACTION_BUTTONS.KEYBOARD
          | translate
      }}</span>
    </button>
  </div>
</app-base-two-layout>

<ion-modal
  #modal
  [isOpen]="isOpenedPlayer"
  [enterAnimation]="enterAnimation"
  [leaveAnimation]="leaveAnimation"
>
  <ng-template>
    <ion-header>
      <ion-toolbar>
        <p class="text-white text-center">Suggested Video</p>
        <span
          slot="end"
          class="bbicon-close-circle text-2xl text-brandSunsetOrange"
          (click)="isOpenedPlayer = false"
        ></span>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="flex justify-center items-center h-full">
        <youtube-player
          [videoId]="selectedVideo.id"
          [playerVars]="playerVars"
          [suggestedQuality]="'highres'"
          placeholderImageQuality="standard"
          (ready)="onPlayerReady($event)"
        ></youtube-player>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
