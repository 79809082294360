import { Injectable } from "@angular/core";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import { BaseService } from "../shared/base.service";
import { GpsLocationService } from "../technician/gps-location.service";
import { HttpClient } from "@angular/common/http";
import { EnvironmentService } from "../environment.service";
import { Router } from "@angular/router";
import { LoaderService } from "../shared/loader/loader.service";
import { GeolocationService } from "../services/geolocation.service";
import { SignalrService } from "./signalr.service";
import { TokenService } from "abp-ng2-module";
import { AbpApplicationService } from "src/shared/abp-application.service";
import { VesselProfileService } from "../services/vessel-profile.service";
import { ImageUploaderService } from "../utilities/image-uploader.service";
import { AudioService } from "../captain-talk/services/audio.service";
import { TokenAuthServiceProxy } from "../../shared/service-proxies/service-proxies";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LogoutService {
  techInFo: any;

  constructor(
    private locationService: GpsLocationService,
    private baseService: BaseService,
    private httpClient: HttpClient,
    private env: EnvironmentService,
    private geolocationService: GeolocationService,
    private loaderService: LoaderService,
    private signalrService: SignalrService,
    private abpService: AbpApplicationService,
    private imageUploader: ImageUploaderService,
    private router: Router,
    private audioService: AudioService,
    private serviceFile: TokenAuthServiceProxy
  ) {
    this.techInFo = JSON.parse(<string>localStorage.getItem("stat-352627390096"));
  }

  async logout() {
    try {
      this.unloadAudio();
      this.loaderService.open();

      this.signalrService.stopConnection();
      this.postLogoutCleanup();
      this.geolocationService.stopTracking();
      this.abpLogout();
      this.clearLocalStorage();

      this.imageUploader.clearLocalStore("userProfileImage");
      await this.performLogout();
      // await this.router.navigateByUrl("/default");
      localStorage.removeItem("completeProfile");
      if (this.router.url !== "/captain-talk/authentication/signin") {
        window.location.href = "/default";
      }
    } catch (error) {
      console.error("Logout failed", error);
    }
  }

  async unloadAudio() {
    this.audioService.unloadAudio();
  }

  private async performLogout() {
    try {
      if (this.techInFo?.provider === "google") {
        // await GoogleAuth.signOut();
      }

      await firstValueFrom(this.serviceFile.logOut(this.env.persona));
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }
  private postLogoutCleanup() {
    this.locationService.setTrackingValue(false);
    this.baseService.clearSessionData();
    this.baseService.clearSession(this.baseService.credKey);
    this.baseService.imageslength = 0;
  }

  clearLocalStorage() {
    localStorage.removeItem("currentYacht");
    localStorage.removeItem("AppEncryptedToken");
    localStorage.removeItem("captainAiData");
    localStorage.removeItem("return_to");
  }

  abpLogout() {
    abp.auth.clearToken();
    abp.auth.clearRefreshToken();
    this.abpService.clearToken();
    // this._tokenService.clearToken();
    // this._tokenService.clearRefreshToken();
  }

  logoutUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${this.env.apiUrl}/TokenAuth/LogOut?persona=${this.env.persona}`).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  }
}
