import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Capacitor } from '@capacitor/core';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import {
	AlertController,
	AnimationController,
	ModalController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { marked } from 'marked';
import { Subscription } from 'rxjs';
import { CameraService } from 'src/app/shared/imageManagement/services/camera.service';
import { SpeechService } from 'src/app/services/speech.service';
import { ToastService } from 'src/app/shared/message/toast.service-impl';
import { LANGUAGE_DATA_PROVIDER } from 'src/app/utilities/language-data';
import { AudioService } from '../../services/audio.service';
import { CaptainTalkBaseService } from '../../services/captain-talk-base/captain-talk-base.service';
import { UtilityService } from '../../services/utility.service';
import { ConfirmCameraOptionComponent } from '../confirm-camera-option/confirm-camera-option.component';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { GoogleAnalyticsCustomService } from 'src/app/services/google-analytics.service';

@Component({
	selector: 'app-conversation',
	templateUrl: './conversation.component.html',
	styleUrls: ['./conversation.component.css'],
})
export class ConversationComponent implements OnInit, OnDestroy {
	@ViewChild('conversationContainer') private conversationContainer: ElementRef;
	@ViewChild(ContextMenuComponent) private contextMenu: ContextMenuComponent;
	@ViewChild('prompt') private prompt: ElementRef;
	@Input() public action: string;
	@Input() public source: 'conversation' | 'pre_prompt' | 'default' =
		'conversation';

	private selectedConversation;
	public isTyping = false;
	public isError = false;
	private isMenuOpen = false;
	private videoDetails = [];
	public conversations: { text: string; type: string; image?: any }[];
	public isGettingAiResponse = false;
	public suggestedQuestions: string[] = [];
	private pressTimeout: any;
	private pressStartTime: number;
	private longPressDuration = 500;
	public isOpenedPlayer = false;
	public selectedVideo: any;
	public playerVars = {
		modestbranding: 1,
		fullscreen: 1,
		cc_lang_pref: localStorage.getItem('lang') || 'en',
		cc_load_policy: 1,
		controls: 0,
		playsinline: 1,
		rel: 0,
		showinfo: 0,
		autoplay: 1,
		mute: 1,
	};
	private player: YT.Player | undefined;
	private captainAiData = JSON.parse(localStorage.getItem('captainAiData'));
	public user = '';
	@Input() firstPrompt: {
		prompt: string;
		context: string;
		session_id?: string;
		image?: any;
		language: string;
	};
	conversationForm: FormGroup;
	@Input() public image: any;

	public isListening = false;
	public LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;
	public isFirstPrompt = false;

	private isListeningSubscription: Subscription;
	private currentMessageSubscription: Subscription;
	public listeningText = '';
	constructor(
		private modalCtrl: ModalController,
		private toastService: ToastService,
		private util: UtilityService,
		private captainTalkService: CaptainTalkBaseService,
		private cdr: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
		private animationCtrl: AnimationController,
		private formBuilder: FormBuilder,
		private speechService: SpeechService,
		private cameraService: CameraService,
		private audioService: AudioService,
		private translate: TranslateService,
		private googleAnalyticsCustomService: GoogleAnalyticsCustomService
	) {
		if (Capacitor.isNativePlatform()) {
			Keyboard.setResizeMode({ mode: KeyboardResize.Body });
		}
	}
	ngOnDestroy(): void {
		if (this.isListeningSubscription)
			this.isListeningSubscription.unsubscribe();
		if (this.currentMessageSubscription)
			this.currentMessageSubscription.unsubscribe();
	}

	ngOnInit(): void {
		if (this.source === 'conversation') {
			this.isFirstPrompt = true;
		}
		this.googleAnalyticsCustomService.setScreenName(
			'CaptainAI',
			'CaptainAI_Screen'
		);
		this.googleAnalyticsCustomService.engageCaptainAIEvent();
		this.listeningText = this.translate.instant(
			this.LANGUAGE_DATA?.CAPTAIN_TALK?.ASK_CAPTAIN_AI?.ACTION_BUTTONS
				?.LISTENING_TEXT
		);
		console.log('Before==>', this.firstPrompt);
		this.firstPrompt.language = localStorage.getItem('lang') || 'en';
		console.log('After====>', this.firstPrompt);
		this.user = this.captainAiData?.firstName?.split('')[0] || '';
		this.conversations = [];
		this.getAiResponse();
		this.conversationForm = this.formBuilder.group({
			prompt: [''],
		});

		this.isListeningSubscription = this.speechService.isListening$.subscribe(
			(isListening) => {
				this.isListening = isListening;
			}
		);

		this.currentMessageSubscription =
			this.speechService.currentMessage.subscribe((message) => {
				if (message && message.length > 0) {
					this.conversationForm.get('prompt').setValue(message);
				}
			});
	}

	ngAfterViewInit() {
		console.log(this.action);
		if (this.action) {
			switch (this.action) {
				case 'camera':
					this.openCamera();
					break;
				case 'image':
					this.focusPrompt();
					break;
				case 'talk':
					this.startListening();
					break;
				case 'keyboard':
					this.focusPrompt();
					break;
				default:
					this.focusPrompt();
					break;
			}
		}
	}

	private resetMicState() {
		this.isListening = false;
		if (this.conversationForm.get('prompt').value == this.listeningText) {
			this.conversationForm.get('prompt').setValue('');
		}
		this.conversationForm.get('prompt').enable();
	}

	private unmuteVideo() {
		if (this.player) {
			this.player.unMute();
			this.player.setVolume(100);
		}
	}

	private removeHtmlTags(text: string) {
		return text.replace(/<\/?[^>]+>/gi, '');
	}

	public onPressStart(event: MouseEvent | TouchEvent, conversation) {
		this.selectedConversation = conversation;
		this.pressStartTime = new Date().getTime();

		this.pressTimeout = setTimeout(() => {
			this.onLongPress(event);
		}, this.longPressDuration);
	}

	public onPressEnd(event: MouseEvent | TouchEvent) {
		clearTimeout(this.pressTimeout);
		const pressDuration = new Date().getTime() - this.pressStartTime;
		if (pressDuration < this.longPressDuration) {
			if (this.isMenuOpen) {
				this.dismissMenu();
				this.isMenuOpen = false;
				return;
			}

			this.handleDynamicClick(event);
		}
	}

	private onLongPress(event: MouseEvent | TouchEvent) {
		this.isMenuOpen = true;
		if (event instanceof MouseEvent) {
			this.contextMenu.showContextMenu(event);
		} else if (event instanceof TouchEvent) {
			const touch = event.changedTouches[0];
			this.contextMenu.showContextMenu({
				clientX: touch.clientX,
				clientY: touch.clientY,
			} as MouseEvent);
		}
	}

	private extractSuggestedQuestions(input) {
		if (Array.isArray(input)) {
			input = input.join('\n');
		}
		const bulletOrNumberedPattern =
			/(?:\d+\.\s+|•\s+|-+\s+|^\d+\.\s+)([^"\n]+|".+?")(?=\n|$)/g;

		let formattedList = [];

		const matches = input.match(bulletOrNumberedPattern);
		if (matches) {
			formattedList = matches.map((item) =>
				item
					.replace(/(?:\d+\.\s+|•\s+|-+\s+)/, '')
					.replace(/["\\]/g, '')
					.trim()
			);
		} else {
			formattedList = input.split('\n');
		}

		return formattedList;
	}

	private getVideosDetail(videos) {
		const videoIds = this.getVideoIdFromUrl(videos);
		this.videoDetails = videoIds.map((videoId) => {
			return {
				id: videoId,
				thumbnail: `https://img.youtube.com/vi/${videoId}/0.jpg`,
			};
		});
	}

	private async typeWriterEffect(
		text,
		conversation: { text: any },
		media,
		speed: number = 10,
		is_Pre_response = false
	): Promise<void> {
		this.isTyping = true;
		let i = 0;
		if (text.length == 0) {
			this.isTyping = false;
			this.isGettingAiResponse = false;
			return;
		}
		return new Promise<void>((resolve) => {
			const type = () => {
				if (i < text.length) {
					conversation.text += text.charAt(i);
					this.cdr.detectChanges();
					i++;
					setTimeout(type, speed);
				} else {
					this.handleCompletion(conversation, media, is_Pre_response, resolve);
				}
			};
			type();
		});
	}

	private handleCompletion(
		conversation: { text: string },
		media: any,
		is_Pre_response: boolean,
		resolve: () => void
	) {
		if (is_Pre_response) {
			resolve();
		} else {
			if (media && (media.images.length > 0 || media.videos.length > 0)) {
				this.insertLinksDynamically(conversation, media);
			}

			this.isGettingAiResponse = false;
			this.isTyping = false;

			setTimeout(() => {
				resolve();
			}, 200);
		}
	}

	private openMedia(link, type, id) {
		console.log('link', id);
		if (type === 'image') {
			window.open(link, '_blank');
		} else if (type === 'video_icon') {
			this.selectedVideo = {
				id,
				title: 'DIY Video',
			};
			this.isOpenedPlayer = true;

			setTimeout(() => {
				document.querySelectorAll('iframe').forEach((iframe) => {
					if (iframe.src.includes(id)) {
						iframe.classList.add('diy-iframe');
					}
				});
			}, 200);
		}
	}

	private getVideoIdFromUrl(videoLinks: string[]) {
		return videoLinks.map((video) => {
			const url = new URL(video);
			return url.searchParams.get('v');
		});
	}

	private insertLinksDynamically(conversation: { text: string }, media) {
		const imageLinksHtml = media?.images
			.map(
				(link) =>
					`<img (load)="${this.onImageLoad()}" src="${link}" type="image"  class="w-full  mb-4 px-1 rounded-xl py-[2px]" .jpg" />`
			)
			.join('');
		const videoLinksHtml = this.videoDetails
			?.map(
				(video) =>
					`
        <div class="flex items-center justify-between relative mb-3">
        <img src="${video?.thumbnail}" role="${video?.id}" type="video" class="w-full h-auto rounded-xl" alt="diy_videos" />
        <img src="assets/svgs/play-icon.svg" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12" type="video_icon"  src alt="diy_videos" role="${video?.id}" />
        </div>
        `
			)
			.join('');

		conversation.text += `<br><b class="mb-3">Suggested Media</b><br/><br/>`;
		conversation.text += `<div>${imageLinksHtml}</div>`;
		conversation.text += `<div>${videoLinksHtml}</div>`;

		this.cdr.detectChanges();
	}

	private handleDynamicClick(event: MouseEvent | TouchEvent) {
		if (event?.type === 'touchend') {
			const target = event.target as HTMLElement;

			if (
				(target.tagName.toLowerCase() === 'img' &&
					target.getAttribute('src')) ||
				target.getAttribute('alt') === 'diy_videos'
			) {
				event.preventDefault();
				const link = target.getAttribute('src');
				const type = target.getAttribute('type');
				const id = target.getAttribute('role');
				this.openMedia(link, type, id);
			}
		}
	}

	private formatText(text) {
		return text.replace(/\n/g, '<br/>');
	}

	private renderMarkdown(markdown: string) {
		const formattedText = this.formatText(markdown);
		const html: any = marked(formattedText);
		const sanitizedHtml: any = this.sanitizer.bypassSecurityTrustHtml(html);
		console.log('sanitizedHtml', sanitizedHtml);
		return sanitizedHtml?.changingThisBreaksApplicationSecurity;
	}

	public onImageLoad() {
		console.log('image loaded'); //TODO: use it to enhance image loading
	}

	public dismissMenu() {
		if (this.contextMenu.showMenu) {
			this.contextMenu.hideContextMenu();
		}
	}

	public handleClick(event: MouseEvent) {
		if (
			this.pressStartTime &&
			new Date().getTime() - this.pressStartTime < this.longPressDuration
		) {
			this.dismissMenu();
		}
	}

	public submitPrompt() {
		this.googleAnalyticsCustomService.CaptainAIChatEvent();
		const text = this.conversationForm.value.prompt;
		if (text?.length === 0 || this.isTyping || this.isGettingAiResponse) {
			return;
		}
		this.sendQuestion(text);
		this.scrollToBottom();
		this.conversationForm.reset();
		this.image = null;
		this.firstPrompt.image = null;
	}

	public sendQuestion(text) {
		this.isFirstPrompt = false;
		const conversation = {
			type: 'user',
			text,
			image: null,
		};

		if (this.image) {
			conversation.image = this.image;
		}
		this.conversations.push(conversation);
		this.scrollToBottom();
		this.firstPrompt.prompt = text;
		if (this.image) {
			this.firstPrompt.image = this.image.file;
		}

		this.conversationForm.get('prompt').setValue('');

		this.getAiResponse();
	}

	public getAiResponse() {
		this.isGettingAiResponse = true;
		this.isError = false;

		if (this.conversations.length > 0) {
			this.firstPrompt.context =
				this.conversations
					.map((conversation) => conversation.type + ': ' + conversation.text)
					.join('\n') + '. The bot is you.';
		}

		this.captainTalkService.getAiResponse(this.firstPrompt).subscribe(
			async (res: any) => {
				if (
					res?.media_suggestions &&
					res?.media_suggestions?.videos.length > 0
				) {
					this.getVideosDetail(res?.media_suggestions?.videos);
				}

				if (res?.suggested_questions && res?.suggested_questions.length > 0) {
					this.suggestedQuestions = this.extractSuggestedQuestions(
						res?.suggested_questions
					);
				}

				if (!this.firstPrompt.session_id) {
					const conversation = {
						type: 'bot',
						text: '',
						image: null,
					};
					if (this.image) {
						conversation.image = this.image;
					}
					this.conversations.push(conversation);
					await this.typeWriterEffect(
						this.renderMarkdown(res.pre_response),
						conversation,
						res?.media_suggestions,
						10,
						true
					);
				}

				const conversation2 = {
					type: 'bot',
					text: '',
					image: null,
				};

				if (this.image) {
					conversation2.image = this.image;
				}

				this.conversations.push(conversation2);
				this.firstPrompt.session_id = res.session_id;

				this.typeWriterEffect(
					this.renderMarkdown(res.main_response),
					conversation2,
					res?.media_suggestions
				);
			},
			(err) => {
				this.isGettingAiResponse = false;
				this.isError = true;
				this.toastService.showToast(
					this.LANGUAGE_DATA?.CAPTAIN_TALK?.TOAST_MESSAGES?.CAPTAIN_AI_ERROR,
					3000,
					'bottom',
					null,
					'danger',
					this.getAiResponse.bind(this),
					this.translate.instant(
						this.LANGUAGE_DATA?.CAPTAIN_TALK?.TOAST_MESSAGES?.RETRY
					)
				);
				console.log('error', err);
			}
		);
	}

	public stopListening() {
		this.speechService.stopListening();
		this.isListening = false;
		this.conversationForm.get('prompt').enable();
		if (this.conversationForm.get('prompt').value == this.listeningText) {
			this.conversationForm.get('prompt').setValue('');
		} else {
			this.submitPrompt();
		}
	}

	public async startListening(isClicked?: boolean) {
		if ((this.isTyping || this.isGettingAiResponse) && isClicked) return;
		await this.speechService.initializeSpeechRecognition();
		this.audioService.playAudio('chime-listen');
		this.isListening = true;
		this.speechService.startListening();
		this.conversationForm.get('prompt').setValue(this.listeningText);
		this.conversationForm.get('prompt').disable();
	}

	public async openCamera() {
		const result = await this.cameraService.takePicture();
		this.image = result;
	}

	public focusPrompt(isClicked?: boolean) {
		if ((this.isTyping || this.isGettingAiResponse) && isClicked) return;
		this.resetMicState();
		setTimeout(() => {
			this.prompt?.nativeElement?.focus();
		}, 500);
	}

	public enterAnimation = (baseEl: HTMLElement) => {
		const root = baseEl.shadowRoot;

		const backdropAnimation = this.animationCtrl
			.create()
			.addElement(root.querySelector('ion-backdrop')!)
			.fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

		const wrapperAnimation = this.animationCtrl
			.create()
			.addElement(root.querySelector('.modal-wrapper')!)
			.keyframes([
				{ offset: 0, opacity: '0', transform: 'scale(0)' },
				{ offset: 1, opacity: '0.99', transform: 'scale(1)' },
			]);

		return this.animationCtrl
			.create()
			.addElement(baseEl)
			.easing('ease-out')
			.duration(500)
			.addAnimation([backdropAnimation, wrapperAnimation]);
	};

	public leaveAnimation = (baseEl: HTMLElement) => {
		return this.enterAnimation(baseEl).direction('reverse');
	};

	public async goBack() {
		this.modalCtrl.dismiss();
	}

	public async contextActionEvent(ev) {
		if (ev === 'good_response') {
			this.util.showAlert({ positive_feedback: true });
		} else if (ev === 'bad_response') {
			await this.util.showAlert({ service_request: true });
		} else if (ev === 'copy') {
			const cleanedText = this.removeHtmlTags(this.selectedConversation.text);
			navigator.clipboard.writeText(cleanedText);
			this.toastService.showToast(
				this.translate.instant(
					this.LANGUAGE_DATA?.CAPTAIN_TALK?.TOAST_MESSAGES?.COPIED
				),
				3000,
				'bottom',
				null,
				'success'
			);
		}
	}

	public onPlayerReady(event: YT.PlayerEvent) {
		this.player = event.target;
		this.unmuteVideo();
	}

	public removeImage() {
		this.image = null;
	}

	public scrollToBottom() {
		try {
			this.conversationContainer.nativeElement.scrollTop =
				this.conversationContainer.nativeElement.scrollHeight;
			if (Capacitor.isNativePlatform()) {
				Keyboard.hide();
			}
		} catch (err) {
			//  error
		}
	}

	public async selectCameraOption(isClicked?: boolean) {
		if ((this.isTyping || this.isGettingAiResponse) && isClicked) return;
		this.resetMicState();
		try {
			const modal = await this.modalCtrl.create({
				component: ConfirmCameraOptionComponent,
				componentProps: {},
				cssClass: 'ai-alerts-modal',
			});

			await modal.present();

			const { data } = await modal.onDidDismiss();

			this.image = data;
		} catch (error) {
			// DO nothing
		}
	}
}
