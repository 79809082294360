import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { CaptainAiTalkComponent } from "../../../shared/captain-ai-talk/captain-ai-talk.component";
import { IonContent, ModalController, Platform } from "@ionic/angular";
import { TalkService } from "../../../shared/captain-ai-talk/talk.service";
// import { UtilityService } from "../../services/utility.service";
import { Router } from "@angular/router";
import { ConversationComponent } from "../conversation/conversation.component";
import { BasicAlertComponent } from "src/app/shared/basic-alert/basic-alert.component";
import { v4 as uuid } from "uuid";
import { BaseService } from "src/app/shared/base.service";
import { LANGUAGE_DATA_PROVIDER } from "src/app/utilities/language-data";
import { TranslateService } from "@ngx-translate/core";
import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { Animation, StatusBar, Style } from "@capacitor/status-bar";
import { environment } from "src/environments/environment";
import { SpeechService } from "src/app/services/speech.service";
import { UtilityService } from "../../services/utility.service";

@Component({
  selector: "app-base-two-layout",
  templateUrl: "./base-two-layout.component.html",
  styleUrls: ["./base-two-layout.component.css"],
})
export class BaseTwoLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild("scrollElement", { static: false }) scrollElementRef: ElementRef<HTMLDivElement>;
  isTyping = false;
  message = "";
  @Input() hasBorder = true;
  @Input() title = "";
  @Input() hasGreeting = true;
  @Input() hasBackButton = true;
  @Input() hasTextArea = true;
  @Input() headerText = "";
  @Input() hasRefresher = false;
  @Input() fullScreen = false;
  @Input() hasScroll = false;
  @Input() videoUrl = "";
  @Input() videoId = "";
  @Input() hasHeader = false;
  @Input() toolTipText;
  @Input() enableRequestService = true;

  @Input() variant: "default" | "clear" = "default";
  // isKeyboardOpen: boolean = false;
  setPadding = false;

  private touchStartListener: () => void;
  isLoggedIn = false;

  @Output() goBack: EventEmitter<any> = new EventEmitter();
  firstName = "";
  season = "";
  textAreaText = "";
  LANGUAGE_DATA = LANGUAGE_DATA_PROVIDER;

  public control = new FormControl();
  @Output() textAreaValue: EventEmitter<string> = new EventEmitter<string>();
  public isFocused = false;
  @Output() textareaFocus: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild("textarea") textarea: ElementRef;
  @ViewChild("content") content: IonContent;

  @Input() rows = 1;
  @Input() enablePlaceholder = true;
  @Input() placeholder = "Select above, type or talk";
  @Output() textareaBlur: EventEmitter<void> = new EventEmitter<void>();
  @Input() showAiLogo = true;
  triggerId: string;
  // hasScrolled: boolean = false;
  buildType = environment.buildType;
  private onChange: any = () => {
    //
  };
  cookieValue: any;

  @Output() refreshed: EventEmitter<any> = new EventEmitter();
  // @HostListener('window:scroll', [])

  constructor(
    private renderer: Renderer2,
    private modalCtrl: ModalController,
    private talkService: TalkService,
    private util: UtilityService,
    private router: Router,
    private baseService: BaseService,
    private platform: Platform,
    private translateService: TranslateService,
    private speechService: SpeechService // TODO: Remove this later after refactoring
  ) {
    this.triggerId = uuid();

    this.cookieValue = localStorage.getItem("lang") || "en";
    this.translateService.setDefaultLang(this.cookieValue);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.addTouchMoveListener();
    }, 200);
  }

  clickLogo() {
    console.log("clicked ai logo");
  }

  async ngOnInit() {
    setTimeout(async () => {
      if (this.platform.is("capacitor")) {
        // if (this.platform.is("android")) {
        // await StatusBar.show({animation: Animation.Fade});
        await StatusBar.setStyle({ style: Style.Light });
        // }
      }
    }, 200);

    this.firstName = this.util.getSingleData("firstName");

    const user = this.baseService.getSession(this.baseService.credKey);
    if (user) {
      this.isLoggedIn = true;
      this.firstName = user.firstName;
    }

    this.season =
      this.cookieValue == "en"
        ? `Hi ${this.firstName}, CaptainAI is here to help!`
        : `Hola ${this.firstName}, ¡CaptainAI está aquí para ayudar!`;
  }

  id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

  goto(action: string) {
    if (action === "exit") {
      this.router.navigate(["/yacht-owner/dashboard"]);
    }
  }

  doRefresh(event) {
    this.refreshed.emit(event);
  }


  async openAIConversation() {
    this.speechService.messageSource.next("");
    this.speechService.isListeningSource.next(false);
    const modal = await this.modalCtrl.create({
      component: ConversationComponent,
      backdropDismiss: false,
      cssClass: "ai-conversation-modal",
      componentProps: {
        firstPrompt: {
          prompt: "",
          context: null,
        },
      },
    });
    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (Capacitor.isNativePlatform()) {
      await Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  onBlur() {
    this.textareaBlur.emit();
    if (!this.placeholder && this.textarea.nativeElement.value === "") {
      this.isFocused = false;
    }
  }

  onFocus() {
    this.baseService.resetKeyboardSettings();
    this.setPadding = true;
    this.textareaFocus.emit();
    this.isFocused = true;
    this.adjustTextareaHeight();
  }

  async openAiInfo() {
    // this.util.showAlert({ ai_info: true });
  }

  private adjustTextareaHeight() {
    const textareaEl = this.textarea.nativeElement;
    textareaEl.style.height = "auto"; // Reset height to auto so that it can shrink if needed
    const newHeight = Math.min(textareaEl.scrollHeight, 200); // Ensure the height doesn't exceed 200px
    textareaEl.style.height = `${newHeight}px`; // Set height
  }

  onInput() {
    this.onChange(this.textarea.nativeElement.value);
    this.adjustTextareaHeight();
    this.textAreaText = this.textarea.nativeElement.value;
    this.isTyping = this.textarea.nativeElement.value.length > 0;
  }

  handleSendClick() {
    this.textAreaValue.emit(this.textAreaText);
    this.control.reset();
    this.isTyping = false;
  }
  openCaptainAITalk() {
    const modal = this.modalCtrl
      .create({
        component: CaptainAiTalkComponent,
        componentProps: {
          data: {
            popoverMessage:
              "Write your descriptions or utilize our speech-to-text functionality by clicking on the microphone icon, which also provides the convenience of automatic language translation as well as can rewrite the message more professionally.",
            value: this.control.value,
            // placeholder: this.placeholder,
            // title: this.label,
          },
        },
      })
      .then((modal) => {
        modal.onDidDismiss().then((result) => {
          if (result.data) {
            this.control.setValue(result.data);
            console.log(result.data);
            this.textAreaText = result.data;
            this.isTyping = result.data.length > 0;
          }
        });

        modal.present();
      });
  }

  async openHelpModal() {
    const modal = await this.modalCtrl.create({
      component: BasicAlertComponent,
      cssClass: "ai-alerts-modal",
      backdropDismiss: false,
      componentProps: {
        title: "Help?",
        message: this.toolTipText || "",
        videoId: this.videoId,
      },
    });

    await modal.present();
  }

  async navigateToServiceRequest() {
    await this.dismissAllModal();

    this.router.navigate(["/yacht-owner/service-orders/create"]);
  }
  async dismissAllModal() {
    let topModal = await this.modalCtrl.getTop();

    while (topModal) {
      await this.modalCtrl.dismiss();
      topModal = await this.modalCtrl.getTop();
    }
  }

  onButtonClick() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.hide();
    }
    this.goBack.emit();
  }

  private addTouchMoveListener() {
    if (this.scrollElementRef && this.scrollElementRef.nativeElement) {
      this.touchStartListener = this.renderer.listen(this.scrollElementRef.nativeElement, "touchmove", (event) =>
        this.onTouchMove(event)
      );
    } else {
      console.error("touch element not found");
    }
  }

  onTouchMove(event: any) {
    if (Capacitor.isNativePlatform()) {
      Keyboard.hide();
    }
  }

  ngOnDestroy() {
    if (this.touchStartListener) {
      this.touchStartListener();
    }
  }
}
