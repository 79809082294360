import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { CameraService } from "src/app/shared/imageManagement/services/camera.service";
import { ImageViewerComponent } from "src/app/shared/imageManagement/image-viewer/image-viewer.component";

@Component({
  selector: "app-confirm-camera-option",
  templateUrl: "./confirm-camera-option.component.html",
  styleUrls: ["./confirm-camera-option.component.css"],
})
export class ConfirmCameraOptionComponent implements OnInit {
  public title = "Take a photo or upload an image.";
  public message = "Ask CaptainAl anything about an image!.";

  constructor(private modalCtrl: ModalController, private cameraService: CameraService) {}

  ngOnInit(): void {}

  onBackClick() {
    this.modalCtrl.dismiss();
  }

  async openCamera() {
    const result = await this.cameraService.takePicture();
    this.modalCtrl.dismiss(result);
  }

  async openGallery() {
    const result = await this.cameraService.pickImage(40,true,1);
    this.openImageViewer(result).then();
  }

  async openImageViewer(images) {
    const modal = await this.modalCtrl.create({
      component: ImageViewerComponent,
      componentProps: {
        data: images,
      },
    });

    await modal.present();

    const { data } = await modal.onDidDismiss();
    if (data) {
      // Take only the fist image
      this.modalCtrl.dismiss(data[0]);
    }
  }
}
