import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BbHeaderComponent } from "./bb-header.component";
import { IonicModule } from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    declarations: [BbHeaderComponent],
    exports: [BbHeaderComponent],
    imports: [CommonModule, IonicModule, TranslateModule],
})
export class BbHeaderModule {}
