import { UtilityServiceProxy } from "src/shared/service-proxies/service-proxies";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { EnvironmentService } from "../environment.service";
import { BehaviorSubject, Observable, tap } from "rxjs";
const STORAGE_KEY = "captainAiData";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  private guideVideosSubject = new BehaviorSubject<string[]>([]);
  public readonly guideVideos$: Observable<string[]> = this.guideVideosSubject.asObservable();
  _guideVideos = [];

  public years: { id: number; value: number }[] = [];

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private utilityServiceProxy: UtilityServiceProxy
  ) {
    this.years = this.getYears();
    console.log("Getting years: ", this.years);
  }

  setGuideVideos(videos: any) {
    this.guideVideosSubject.next(videos);
  }

  getUserId() {
    const userSessionData = JSON.parse(localStorage.getItem("sessionData"));
    return userSessionData?.result.userId;
  }

  getStorageData(key: string) {
    const userId = this.getUserId();
    const storageData = localStorage.getItem(`${userId}_${key}`);
    return JSON.parse(storageData);
  }
  setStorageData(key: string, value: any) {
    const userId = this.getUserId();
    localStorage.setItem(`${userId}_${key}`, JSON.stringify(value));
  }

  getSingleData(key: string) {
    const data = JSON.parse(localStorage.getItem(STORAGE_KEY));
    return data[key];
  }


  removeStorageData(key: string) {
    const userId = this.getUserId();
    localStorage.removeItem(`${userId}_${key}`);
  }

  initializeGuideVideos(_guideVideos) {
    // this.getGuideVideos().subscribe((response) => {
    this.setGuideVideos(_guideVideos);
    this._guideVideos = _guideVideos;
    // });
  }

  getYears() {
    const startYear = 1960;
    const endYear = new Date().getFullYear() + 1;

    const resp = Array.from({ length: endYear - startYear + 1 }, (value, index) => endYear - index).map((x) => ({
      id: x,
      value: x,
    }));

    return resp;
  }

  // initializeGuideVideos() {
  //     this.getGuideVideos().subscribe((response) => {
  //         this.setGuideVideos(response.result);
  //         this._guideVideos = response.result;
  //     });
  // }

  getGuideVideos(): Observable<any> {
    return this.http.get(this.env.apiUrl + "services/app/Utility/GetBoatBotVideos");
  }

  getGuideVideo(videoId: number): Observable<any> {
    const params = {
      id: videoId.toString(),
    };

    return this.http.get(this.env.apiUrl + "services/app/Yachts/GetBoatBotVideos", {
      params,
    });
  }

  formatPhoneNumber(phoneNumberString: string) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^1?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }
}

// Legacy code
// TODO: Replace in all components with formatPhoneNumber function in class above
export const formatNumber = (value: string) => {
  if (!value) return "";

  const phoneNumber = value.replace(/\D/g, "");

  // Remove leading '1' if present
  const formattedPhoneNumber = phoneNumber.replace(/^1/, "");

  // Format the remaining digits
  const finalFormattedPhoneNumber = formattedPhoneNumber.replace(/(\d{3})(\d{3})(\d{4}).*/, "($1) $2-$3");

  return finalFormattedPhoneNumber;
};
