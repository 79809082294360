<ion-app mode="ios" >
    <div
        class="flex flex-wrap row content-center"
        *ngIf="!networkStatusChangeValue"
    >
        <ng-container>
            <div class="w-full p-4 z-[99999999] fixed top-0 shadow-sm">
                <div
                    class="bg-dsError font-bold text-white text-center flex w-full items-center p-4 py-3 rounded-lg text-base"
                >
                    <img
                        class="text-white w-8 h-8"
                        src="assets/svgs/wifi-off-svgrepo-com.svg"
                    />

                    <label class="ml-3">No internet connection.</label>
                </div>
            </div>
        </ng-container>
    </div>

    <app-camera
        style="position: absolute; z-index: 99999; width: 100%"
    ></app-camera>

    <ion-router-outlet [ngClass]="{'boxed-layout-container': platform_mode === 'desktop'}"></ion-router-outlet>
    <app-loader [isSlowInternet]="isSlowInternet"></app-loader>
</ion-app>
