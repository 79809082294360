import { ModalController, Platform } from "@ionic/angular";
import { Injectable } from "@angular/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { BaseService } from "../../base.service";
import { PermissionDeniedComponent } from "../../permission-denied/permission-denied.component";
import { ServiceOrderHelper } from "../../../yacht-owner/service-orders/service-order-helper";
import { ImagePreviewComponent } from "../../image-preview/image-preview.component";

@Injectable({
  providedIn: "root",
})
export class CameraService {
  constructor(private baseService: BaseService, private modalCtrl: ModalController, private platform: Platform) {}

  public async takePicture(quality = 40, resize = true) {
    const permission = await Camera.checkPermissions();

    if (permission.camera === "denied") {
      this.showPermissionAlert("Camera");
    }
    try {
      const image = await Camera.getPhoto({
        quality,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      if (image) {
        const res = await this.dataUrlToFile(image.webPath, `${BaseService.uuid()}.png`);

        let imageBlob: Blob = null;
        let outputFile: File = null;

        if (resize) {
          imageBlob = await this.resizeImage(res, 800, 600);
          outputFile = this.blobToFile(imageBlob, `${BaseService.uuid()}_resized.png`);
        } else {
          outputFile = await this.dataUrlToFile(image.webPath, `${BaseService.uuid()}.png`);
        }
        return {
          file: outputFile,
          image: await this.convertBlobToBase64(res),
          clientId: BaseService.uuid(),
          user:
            this.baseService.getUserProfile().role === "YachtOwner"
              ? ServiceOrderHelper.YACHT_OWNER_TAG
              : ServiceOrderHelper.TECHNICIAN_TAG,
        };
      }
      return { file: {} as File, image: "" };
    } catch (error) {
      return { file: {} as File, image: "" };
      throw error;
    }
  }

  public async cameraGallery() {
    try {
      const permission = await Camera.checkPermissions();
      if (permission.photos === "denied" || permission.camera === "denied") {
        this.showPermissionAlert("Photos");
      }

      const image = await Camera.getPhoto({
        quality: 40,
        allowEditing: false,
        resultType: CameraResultType.Uri,
        source: CameraSource.Prompt,
        promptLabelCancel: "Cancel",
      });

      if (image) {
        const result = await this.dataUrlToFile(image.webPath, `${BaseService.uuid()}.png`);
        const resizedImageUri = await this.resizeImage(result, 800, 600);

        return {
          file: resizedImageUri,
          image: await this.convertBlobToBase64(result),
          clientId: BaseService.uuid(),
          format: image.format,
        };
      }
      return { file: {}, image: "" };
    } catch (error) {
      return { file: {}, image: "" };
    }
  }

  public async pickAndroidImage(quality = 40, limit=5): Promise<any[]> {
    try {
      const permission = await Camera.checkPermissions();
      if (permission.photos === "prompt") {
        const permissionResult = await this.requestGalleryPermission();
        if (permissionResult.photos !== "granted") {
          return [];
        }
      }

      if (permission.photos === "prompt-with-rationale") {
        this.showPermissionAlert("Photos");
        return [];
      }

      const images = await Camera.pickImages({
        quality,
        presentationStyle: "popover",
        limit,
      });

      const promises = images.photos.map(async (image) => {
        const res = await this.dataUrlToFile(image.webPath, `${BaseService.uuid()}.png`);

        const imageBlob = await this.resizeImage(res, 800, 600);

        const resizedFile = await this.blobToFile(imageBlob, `${BaseService.uuid()}_resized.png`);

        const base64Image = await this.convertBlobToBase64(resizedFile);

        return {
          file: resizedFile,
          image: base64Image,
          clientId: BaseService.uuid(),
          user:
            this.baseService.getUserProfile().role === "YachtOwner"
              ? ServiceOrderHelper.YACHT_OWNER_TAG
              : ServiceOrderHelper.TECHNICIAN_TAG,
        };
      });
      return await Promise.all(promises);
    } catch (error) {
      throw error;
    }
  }

  private async requestGalleryPermission() {
    try {
      const result = await Camera.requestPermissions({
        permissions: ["photos"],
      });
      console.log("Gallery permission", result);

      if (result.photos !== "granted") {
        console.error("Gallery permission denied.", result);
      } else {
        console.error("Gallery permission granted", result);
      }

      return result;
    } catch (error) {
      console.error("Error requesting gallery permission:", error);
      throw error;
    }
  }

  public async pickImage(quality = 40, resize = true, limit=5) {
    console.log({limit: limit});
    if (this.platform.is("android")) {
      return this.pickAndroidImage(quality, limit);
    } else {
      const permission = await Camera.checkPermissions();
      if (permission.photos === "denied") {
        this.showPermissionAlert("Camera");
      }

      const images = await Camera.pickImages({
        quality,
        presentationStyle: "popover",
        limit,
      });

      const promises = images.photos.map(async (image) => {
        const res = await this.dataUrlToFile(image.webPath, `${BaseService.uuid()}.png`);

        let imageBlob: Blob = null;
        let outputFile: File = null;
        if (resize) {
          imageBlob = await this.resizeImage(res, 800, 600);
          outputFile = this.blobToFile(imageBlob, `${BaseService.uuid()}_resized.png`);
        } else {
          outputFile = await this.dataUrlToFile(image.webPath, `${BaseService.uuid()}.png`);
        }

        const base64Image = await this.convertBlobToBase64(outputFile);

        return {
          file: outputFile,
          image: base64Image,
          clientId: BaseService.uuid(),
          user:
            this.baseService.getUserProfile().role === "YachtOwner"
              ? ServiceOrderHelper.YACHT_OWNER_TAG
              : ServiceOrderHelper.TECHNICIAN_TAG,
        };
      });

      return Promise.all(promises);
    }
  }

  async dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
    //Todo can we add the file type dynamically { type: 'image/png' }
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: "image/png" });
  }

  public convertBlobToBase64 = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result.toString());
      };
      reader.readAsDataURL(blob);
    });

  async showPermissionAlert(type: string) {
    let text = "";
    if (type == "Photos") {
      text =
        "To personalize your profile, showcase your boat and attach images to service requests, we need access to your photo library. This allows you to choose a profile and boat picture directly from your library. This permission was previously denied. For a more tailored experience, please enable photo access in the app settings.";
    } else {
      text =
        "To capture images to update your profile, boat pictures and service request images instantly, we need access to your camera. This lets you take photos directly within the app for a seamless experience. This permission was previously denied. To enjoy this feature, please enable camera access in the app settings.";
    }
    const modal = await this.modalCtrl.create({
      component: PermissionDeniedComponent,
      componentProps: {
        header: `${type} Access Needed`,
        text: text,
      },
      cssClass: "blurry-backdrop-auto",
    });

    modal.present();
  }

  async resizeImage(file, maxWidth, maxHeight) {
    return new Promise<Blob>((resolve) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          if (width / maxWidth > height / maxHeight) {
            width = maxWidth;
            height = (maxWidth * img.height) / img.width;
          } else {
            height = maxHeight;
            width = (maxHeight * img.width) / img.height;
          }
        }

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.7
        );
      };
    });
  }

  async previewImage(imageUrls: string[]) {
    try {
      const modal = await this.modalCtrl.create({
        component: ImagePreviewComponent,
        componentProps: {
          imageUrls,
        },
        cssClass: "blurry-backdrop-auto",
      });

      await modal.present();
    } catch (error) {
      console.error("Error opening image preview modal", error);
    }
  }

  blobToFile(blob, fileName) {
    return new File([blob], fileName, { type: blob.type });
  }
}
