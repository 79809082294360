<ion-header class="ion-no-border">
  <ion-toolbar class="bg-clear">
    <button type="button" slot="start" (click)="onBackClick()" class="p-2 rounded-full bg-brandSunsetOrange mt-2 ml-5">
      <ion-ripple-effect></ion-ripple-effect>
      <span class="bbicon-chevron-left text-2xl text-white"></span>
    </button>
  </ion-toolbar>
</ion-header>
<div class="px-6 pb-6">
  <h2 class="text-2xl font-bold text-brandDeepSeaBlue mb-4 text-center">
    {{ title }}
  </h2>
  <p class="text-lg px-4 text-center font-medium text-black leading-normal" [innerHTML]="message"></p>
  <div class="flex flex-col gap-2 mt-4">
    <app-button variant="secondary" label="Take Photo" (buttonClick)="openCamera()"></app-button>
    <app-button variant="secondary" label="Upload Image" (buttonClick)="openGallery()"></app-button>
  </div>
</div>
